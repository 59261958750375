import { Flex } from '@chakra-ui/layout';
import { SimpleDashboard } from 'layouts/dash/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import EstresseGraph from './components/EstresseGraph';
import { CountResponses } from './types';

export default function Estresse(props: SimpleDashboard) {
  const { userId } = props;
  const [info, setInfo] = useState<CountResponses | null>(null);

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations: [userId] })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'));
  }, [userId]);

  if (!info) return null;

  return (
    <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center">
      <EstresseGraph groupTotal={info?.groupTotal} arrowText={info?.responses?.[0]?.name ?? "" } />
    </Flex>
  );
}
