import { Flex } from '@chakra-ui/layout';
import { ConsolidatedChart } from 'layouts/dash/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import EstresseGraph from './components/EstresseGraph';
import { CountResponses } from './types';

export default function EstresseConsolidated(props: ConsolidatedChart) {
  const { evaluations } = props;
  const [info, setInfo] = useState<CountResponses | null>(null);

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations: evaluations })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'));
  }, [evaluations]);

  if (!info) return null;

  return (
    <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center">
      <EstresseGraph groupTotal={info?.groupTotal} arrowText="Média" />
    </Flex>
  );
}
